.form {
  [class~='input-field']:last-of-type  {
    border-bottom: none;
  }

  .input-field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #E5E5E5;

    input, label {
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: $textColor;
      font-family: 'Montserrat', sans-serif;
      text-align: right;
      padding-bottom: 15px;
    }
  }

  input {
    border: none;

    &:focus {
      border: none;
      outline: none;
    }
  }

  .custom-checkbox {

    /* The container */
    .container {
      display: block;
      position: relative;
      padding-left: 40px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 12px;
      line-height: 14px;
      color: $thirdColor;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #ffffff;
      border: 1px solid #E5E5E5;
      border-radius: 3px;
      margin-top: 3px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: $secondaryColor;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 7px;
      top: 2px;
      width: 4px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .transparent-button {
    display: block;
    margin-left: auto;
    color: $secondaryColor;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    text-align: right;
    background: transparent;
    padding: 0;
    cursor: pointer;
  }

  .button {
    background: $secondaryColor;
    box-shadow: 0 0 30px rgba(52, 185, 224, 0.5);
    border-radius: 10px;
    font-weight: 600;
    color: white;
    font-size: 15px;
    transition: all 0.25s ease-in-out;
    padding: 15px 30px !important;
    display: block;
    width: 260px;
    margin: 20px auto;
    cursor: pointer;

    &:disabled {
      background-color: #d0d0d0;
      box-shadow: none;
      transition: all 0.25s ease-in-out;
    }
  }

  &.-auth {
    padding: 0 30px;
    max-width: 350px;
    margin: 0 auto;

    input {
      display: block;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 10px;
      font-family: 'Montserrat', sans-serif;
      padding: 15px 10px;
      width: 100%;
    }
  }

  .backlink {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;

    &::before {
      content: "🠔";
      margin-right: 5px;
      font-size: 17px;
    }
  }
}

.bonus-caption {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 50px;

  &__button {
    color: $secondaryColor;
    display: block;
    cursor: pointer;
  }
}